import React from "react";
import { connect } from "react-redux";

// Pass User
const Home = ({ user }) => {
  return (
    <main style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      <div>Loading...</div>
    </main>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);