import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { getVerifyQrData } from '../services/actions/api';
import { connect } from 'react-redux';

const MobileLogin = (props) => {
  const [qrObj, setQrobj] = useState({});

  useEffect(() => {
    props.getVerifyQrData((res) => {
      if (res.type === 'success'){
        setQrobj(`SMSTO:${res.data.toNumber}:${res.data.msg_body}`)
      }
    })
  }, []);

  return (
    <main style={{ display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden", }}>
      <article style={{ display: "flex", flexDirection: "column" }}>
        <QRCode size={150} value={qrObj} />
      </article>
    </main>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  getVerifyQrData
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileLogin);