import React, { useEffect, useState } from "react";

import { startRegistration, startAuthentication } from '@simplewebauthn/browser';
import { Box } from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";

const PasskeyAuth = (props) => {
  const { auth_type } = useParams();
  const [username, setUsername] = useState(`Step3 Wallet ${moment().format('MM/DD/YYYY')}`);
  const [user, setUser] = useState({ type: "", data: {} });
  const [walletResponse, setWalletResponse] = useState({ type: "", data: {}, message: "" });
  const handleRegister = async () => {
    try {
      const regResp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/generate-registration-options?loc=${encodeURIComponent(window.location.origin)}&username=${username}`);
      const response = await regResp.json();
      const regOptions = response.data;

      // Check if regOptions are valid
      if (!regOptions.challenge || !regOptions.user || !regOptions.rp || !regOptions.authenticatorSelection) {
        return;
      }
      const attResp = await startRegistration(regOptions);

      const verificationResp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/verify-registration?loc=${encodeURIComponent(window.location.origin)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          challenge: regOptions.challenge,
          username,
          attResp,
        }),
      });

      const verificationResult = await verificationResp.json();

      if (verificationResult.type === 'success') {
        setUser(verificationResult);
        window.close();
      } 
    } catch (error) {
      window.opener.postMessage(
        {
          event: "step3_wallet_response",
          data: walletResponse
        },
        "*"
      );
      window.close();
    }
  };
  
  const handleAuthenticate = async () => {
    try{
      const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/generate-authentication-options?loc=${encodeURIComponent(window.location.origin)}&username=${username}`);
      const response = await resp.json();
      const options = response.data;

      const authResp = await startAuthentication(options);

      const verificationResp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/verify-authentication?loc=${encodeURIComponent(window.location.origin)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({...authResp, challenge: options.challenge}),
      });

      const verificationResult = await verificationResp.json();
      if (verificationResult.type === 'success'){
        setUser(verificationResult);
        window.close();
      }
    } catch (e) {
      console.log(`authenticate response =>`, e)
      window.opener.postMessage(
        {
          event: "step3_wallet_response",
          data: walletResponse
        },
        "*"
      );
      window.close();
    }
  };

  useEffect(() => {
    if (auth_type === "register") {
      handleRegister()
    }
    if (auth_type === "login") {
      handleAuthenticate()
    }
  }, []);


  // useEffect(() => {
  //   if (walletResponse.type === '') {
  //     window.opener.postMessage(
  //       {
  //         event: "step3_wallet_response",
  //         data: walletResponse
  //       },
  //       "*"
  //     );
  //   }
  // },[walletResponse])

  useEffect(() => {
    window.opener.postMessage(
      {
        event: "step3_user_response",
        data: user
      },
      "*"
    );
  },[user])

  return (
    <>
      <main style={{ maxWidth: '99vw', margin: "auto", overflow: "hidden" }}>
        <article>
          { (
            <Box margin="0 auto" display="flex" height="100vh" justifyContent="center" alignItems="center">
              <img src="/assets/step3_logo.png" alt="Step3" />
            </Box>
          )}
        </article>
      </main>
    </>
  );
};

export default PasskeyAuth;
