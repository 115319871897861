import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
// let default_sign = {"time_stamp":1659962738805,"signature":"0xbfb020073b5840cd1bddafb73becfb8d66e582715b98e8dc6564875082ef43680ce4b25767525c830c204baf508a171076181691744f63f48e6424c109d70ef61c"};
// axios.defaults.headers={...axios.defaults.headers,...default_sign};

axios.interceptors.response.use(undefined, (err) => {
  if (err.response.status === 400 || 401 || 403) {
    return err.response;
  }
});

export function getVerifyQrData(cb) {
  return async (dispatch) => {
    let qrdata = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/notification/generate_qr`);
    cb(qrdata.data)
  }
}

export function setPageArea() {
  return async (dispatch) => {
    dispatch({
      type: "PAGE_SET_AREA",
      data: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  };
}

export function signUpUser(data, callback) {
  return async (dispatch) => {
    console.log("data =>",data)
    dispatch({ type: "LOGIN_REQUEST", providerType: "google" });
    let user_obj = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/client/signup`,
      { ...data, credential: data.credential }
    );
    if (user_obj.data.type == "success") {
      dispatch({
        type: "LOGIN_SUCCESS",
        data: user_obj.data.data,
      });
    } else {
      dispatch({
        type: "LOGIN_ERROR",
        data: user_obj.data.message,
      });
    }
    callback(user_obj.data);
  };
}

export function googleSignUpUser(data, callback) {
  return async (dispatch) => {
    console.log("data =>",data)
    dispatch({ type: "LOGIN_REQUEST", providerType: "google" });
    let user_obj = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/google/login`,
      { ...data, credential: data.credential }
    );
    if (user_obj.data.type == "success") {
      dispatch({
        type: "LOGIN_SUCCESS",
        data: user_obj.data.data,
      });
    } else {
      dispatch({
        type: "LOGIN_ERROR",
        data: user_obj.data.message,
      });
    }
    callback(user_obj.data);
  };
}

export function appleSignup(data, callback) {
  return async (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST", providerType: "apple" });
    let user_obj = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/apple/login`,
      { ...data }
    );
    if (user_obj.data.type == "success") {
      dispatch({
        type: "LOGIN_SUCCESS",
        data: user_obj.data.data,
      });
    } else {
      dispatch({
        type: "LOGIN_ERROR",
        data: user_obj.data.message,
      });
    }
    callback(user_obj.data);
  };
}

export function deemSignup(data, path_name, callback) {
  return async (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST", providerType: "deem" });
    let user_obj = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/deem/login`,
      { ...data, path_name: path_name }
    );
    if (user_obj.data.type == "success") {
      dispatch({
        type: "LOGIN_SUCCESS",
        data: user_obj.data.data,
      });
    } else {
      dispatch({
        type: "LOGIN_ERROR",
        data: user_obj.data.message,
      });
    }
    callback(user_obj.data);
  };
}
export function updateUser(data) {
  console.log("update User =>", data);
  return async (dispatch) => {
    console.log("update User again =>", data);
    dispatch({ type: "LOGIN_REQUEST", providerType: "google" });
    let user_obj = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/account_update`,
      { ...data }
    );
    if (user_obj.data.type == "success") {
      dispatch({
        type: "LOGIN_UPDATE_SUCCESS",
        data: user_obj.data.data,
      });
    } else {
      dispatch({
        type: "LOGIN_ERROR",
        data: user_obj.data.message,
      });
    }
  };
}

export async function loginUser(data) {
  let user_obj = await axios.post(
    `${process.env.REACT_APP_BASE_API_URL}/api/client/login`,
    data
  );
  axios.defaults.headers = {
    ...axios.defaults.headers,
    time_stamp: data.time_stamp,
    signature: data.signature,
  };
  return user_obj.data;
}

export function logoutUser() {
  return async (dispatch) => {
    axios.defaults.headers = {
      ...axios.defaults.headers,
      time_stamp: "",
      signature: "",
    };
    dispatch({ type: "RESET_USER" });
  };
}

export function kmsCreateAccount(data, callback) {
  return async (dispatch) => {
    try {
      let user_obj = await axios.post(
        `${process.env.REACT_APP_AWS_KMS_URL}/api/create_account`,
        { wallet: data.wallet, wallet_type: data.wallet_type }
      );
      if (user_obj.data.type == "success") {
        callback(user_obj.data);
      } else {
        callback(user_obj.data);
        dispatch({
          type: "LOGIN_ERROR",
          data: user_obj.data.message,
        });
      }
    } catch (e) {
      dispatch({
        type: "LOGIN_ERROR",
        data: e.message,
      });
    }
  };
}

export function kmsSignAccount(data, callback) {
  return async (dispatch) => {
    try {
      let user_obj = await axios.post(
        `${process.env.REACT_APP_AWS_KMS_URL}/api/signInAccount`,
        { ...data }
      );
      if (user_obj.data.type == "success") {
        dispatch({
          type: "LOGIN_UPDATE_SIGNATURE_SUCCESS",
          data: user_obj.data.message,
        });
        callback(user_obj.data);
      } else {
        dispatch({
          type: "LOGIN_ERROR",
          data: user_obj.data.message,
        });
      }
    } catch (e) {
      console.log("e.message =>", e.message);
    }
  };
}

// Email
export function emailSignup(data, callback) {
  return async (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST", providerType: "email" });
    let user_obj = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/email/login`,
      { ...data }
    );
    // if(user_obj.data.type == "success"){
    //   dispatch({
    //     type:'LOGIN_SUCCESS',
    //     data: user_obj.data.data
    //   })

    // } else {
    //   dispatch({
    //     type:'LOGIN_ERROR',
    //     data: user_obj.data.message
    //   })
    // }
    callback(user_obj.data);
  };
}

export function resendEmail(data, callback) {
  return async (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST", providerType: "email" });
    let user_obj = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/email/reSendEmail`,
      { ...data }
    );
    // if(user_obj.data.type == "success"){
    //   dispatch({
    //     type:'LOGIN_SUCCESS',
    //     data: user_obj.data.data
    //   })

    // } else {
    //   dispatch({
    //     type:'LOGIN_ERROR',
    //     data: user_obj.data.message
    //   })
    // }
    callback(user_obj.data);
  };
}
export function checkEmailLogin(data, callback) {
  return async (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST", providerType: "email" });
    let user_obj = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/email/checkEmailConfirm`,
      { ...data }
    );
    if (user_obj.data.type == "success") {
      dispatch({
        type: "LOGIN_SUCCESS",
        data: user_obj.data.data,
      });
    }
    callback(user_obj.data);
  };
}
// End of email
