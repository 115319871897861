import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { connect } from "react-redux";
import {
  kmsCreateAccount,
  kmsSignAccount,
  updateUser,
  appleSignup,
  emailSignup,
  googleSignUpUser
} from "../services/actions/api";
import AppleLogin from "react-apple-login";
import {
  Box,
  Button,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import helper from "../utils/index";

const Landing = (props) => {
  let isLoggedIn = useRef(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const divRef = useRef(null);
  const { user } = props;
  const [walletClicked, setWalletClicked] = useState("")
  let [loginStatus, setLoginStatus] = useState("Loading...");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [signMessage, setSignMessage] = useState(
    `Welcome to Texas Eclipse 2024!\n\nSigning this message will not trigger a blockchain transaction, cost fees or allow us to transfer contents from your wallet.`
  );

  let memberInfoData = {
    name: ``,
    logo: "",
    bg_color: "#0A2033",
    btn_color: "#0C8BF4",
    btn_text_color: "#ffffff",
    txt_color: "#000000",
  };
  const [memberInfo, setMemberInfo] = useState(memberInfoData);
  const [themeType, setThemeType] = useState("");

  let interVal;

  const handleGoogle = (data) => {
    setLoading(true);
    props.googleSignUpUser(data, (response) => {
      if (response.type == "error") {
        setError(response.message);
      }
    });
  };

  // for handling iframe messaging
  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (event && event.data && event.data.theme_type == "admin") {
        setThemeType(event.data.theme_type);
      }
      if (event && event.data && event.data.event == "sign_data") {
        setSignMessage(event.data.message);
        setMemberInfo(event.data.member);
      }
    });
    return () => {
      clearInterval(window.timerInterval);
    };
  }, []);
  console.log("user.data =>",walletClicked)
  useEffect(() => {
    if (Object.keys(user.data).length > 0 && isLoggedIn.current == 0) {
      if (user.data.public_address == null || user.data.public_address == "") {
        setLoginStatus("Creating Account...");
        props.kmsCreateAccount(user.data, (resp) => {
          if (resp.type == "success") {
            props.updateUser({
              jwt_token: user.data.token,
              public_address: resp.data.public_address,
              encrypted_buffer: resp.data.buffer_token,
            });
          } else {
            setLoading(false);
            setError(resp.message);
          }
        });
      } else {
        setLoginStatus("Signing Account...");
        props.kmsSignAccount(
          {
            wallet: user.data.wallet,
            wallet_type: user.data.wallet_type,
            secrete_key: user.data.encrypted_buffer,
            address: user.data.public_address,
            message: signMessage,
          },
          (response) => {
            setLoading(false);
            if (response.type == "success") {
              window.parent.postMessage(
                {
                  event: "sign_user_px",
                  profile_img: user.data.picture,
                  email: user.data.wallet,
                  wallet_type: walletClicked === '' ? user.data.wallet_type : walletClicked?.toLowerCase(),
                  las_accessed_by: user.data.las_accessed_by,
                  created_by: user.data.created_by,
                  eth_address: user.data.public_address,
                  signature: response.signature,
                  fname: user.data?.firstName,
                  lname: user.data?.lastName,
                },
                "*"
              );
              isLoggedIn.current = 1;
            } else {
              setError(response.message);
            }
          }
        );
      }
    }
  }, [user.data]);

  useEffect(() => {
    /* global google */
    if (divRef.current) {
      if (window.google) {
        google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleGoogle,
        });
        google.accounts.id.renderButton(divRef.current, {
          shape: "pill",
          theme: "outline",
          size: "large",
        });
      }
    }
  }, [divRef.current]);

  let appleResponse = (response) => {
    if (!response.error) {
      props.appleSignup(response, (res) => {
        if (res.type == "error") {
          setError(res.message);
        }
      });
    }

    if (JSON.parse(JSON.stringify(response.error))?.error) {
      setWalletClicked("");
      setLoading(false);
      window.parent.postMessage(
        {
          event: "wallet_clicked",
          iframeBtnClickType: "",
          subIframeBtnClickType: 'apple'
        },
        "*"
      );
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("codeResponse =->",codeResponse)
      handleGoogle(codeResponse);
    },
    onError: () => {
      setWalletClicked("");
      setLoading(false);
      window.parent.postMessage(
        {
          event: "wallet_clicked",
          iframeBtnClickType: "",
          subIframeBtnClickType: 'google'
        },
        "*"
      );
    },
    onNonOAuthError: () => {
      setWalletClicked("");
      window.parent.postMessage(
        {
          event: "wallet_clicked",
          iframeBtnClickType: "",
          subIframeBtnClickType: 'google'
        },
        "*"
      );
    },
    flow: "auth-code",
  });

  const handleSocialClick = (type) => {
    if (walletClicked == "") {
      setWalletClicked(type);
      window.parent.postMessage(
        {
          event: "wallet_clicked",
          iframeBtnClickType: type,
        },
        "*"
      );
    }
  }

  return (
    <>
      <main style={{ maxWidth: '99vw', margin: "auto", overflow: "hidden" }}>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <article>
          { (
            <>
              <Box
                sx={{ p: "15px", borderRadius: "7px", border: "1px solid #000", cursor: walletClicked != "" ? "default" : "pointer", opacity: walletClicked != "" ? "0.6" : "1", background: walletClicked == "Google" && `rgba(${helper.ahex_to_rba(memberInfo.btn_color)}, 0.1)`, "&:hover": { background: `rgba(${helper.ahex_to_rba(memberInfo.btn_color)}, 0.1)` } }}
                onClick={(e) => { handleSocialClick('Google'); googleLogin();}}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img src={`/assets/google-icon.svg`} alt={`google icon`} />
                      <Typography variant="h5" className="font-gs-md" sx={{ ml: "10px", fontSize: "1rem", lineHeight: "normal", color: "#000" }}>
                        Google
                      </Typography>
                    </Box>
                    {walletClicked === "Google" && <div className="spinner" />}
                  </Box>
                </Box>
              </Box>
              

              <AppleLogin
                clientId="com.polyientx.auth.client"
                redirectURI={`${process.env.REACT_APP_BASE_APPLE_URL}/api/apple/login`}
                usePopup={true}
                callback={appleResponse} // Catch the response
                scope="email name"
                responseMode="query"
                render={(
                  renderProps //Custom Apple Sign in Button
                ) => (
                  <Box
                    sx={{ p: "15px", borderRadius: "7px", border: "1px solid #000", mt: "27px", cursor: walletClicked != "" ? "default" : "pointer", opacity: walletClicked != "" ? "0.6" : "1", background: walletClicked == "Apple" && `rgba(${helper.ahex_to_rba(memberInfo.btn_color)}, 0.1)`, "&:hover": { background: `rgba(${helper.ahex_to_rba(memberInfo.btn_color)}, 0.1)` } }}
                    onClick={(e) => {
                      if (walletClicked == "") {
                        handleSocialClick("Apple");
                        renderProps.onClick(e);
                      }
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img src={`/assets/apple-icon.svg`} alt={`apple icon`} />
                          <Typography variant="h5" className="font-gs-md" sx={{ ml: "10px", fontSize: "1rem", lineHeight: "normal", color: "#000" }}>
                            Apple
                          </Typography>
                        </Box>

                        {walletClicked == "Apple" && <div className="spinner" />}
                      </Box>
                    </Box>
                  </Box>
                )}
              />
            </>
          )}
        </article>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  kmsCreateAccount,
  kmsSignAccount,
  updateUser,
  appleSignup,
  emailSignup,
  googleSignUpUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
