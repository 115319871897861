import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";

import Landing from "./../pages/Landing";
import Step3 from "./../pages/Step3";
import Social from "./../pages/Social";
import PasskeyAuth from "./../pages/PasskeyAuth";
import Email from "./../pages/Email";
import Deem from "./../pages/Deem";
import Home from "./../pages/Home";
import MobileLogin from "../pages/Mobile";
import { GoogleOAuthProvider } from "@react-oauth/google";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = _ScrollToTop;

function RouteWrapper({ component: Component, ...rest }) {
  return <Component {...rest} />;
}

export const RoutesObj = (props) => {
  const { user } = props;

  return (
    <BrowserRouter>
      <ScrollToTop>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Routes>
            {user.login ? (
              <>
                <Route
                  exact
                  path="/home"
                  element={<RouteWrapper exact component={Home} {...props} />}
                />
                <Route
                  exact
                  path="/social"
                  element={<RouteWrapper exact component={Social} {...props} />}
                />
                <Route path="*" element={<Navigate to="/home" replace />} />
              </>
            ) : (
              <>
                <Route
                  exact
                  path="/"
                  element={
                    <RouteWrapper exact component={Landing} {...props} />
                  }
                />
                <Route
                  exact
                  path="/step3"
                  element={<RouteWrapper exact component={Step3} {...props} />}
                />

                <Route
                  exact
                  path="/social"
                  element={<RouteWrapper exact component={Social} {...props} />}
                />
                <Route
                  exact
                  path="/passkey_auth/:auth_type"
                  element={<RouteWrapper exact component={PasskeyAuth} {...props} />}
                />

                <Route
                  exact
                  path="/email"
                  element={<RouteWrapper exact component={Email} {...props} />}
                />
                <Route
                  exact
                  path="/deem/bg"
                  element={<RouteWrapper exact component={Deem} {...props} />}
                />
                <Route
                  exact
                  path="/deem/aiea"
                  element={<RouteWrapper exact component={Deem} {...props} />}
                />
                <Route
                  exact
                  path="/mobile"
                  element={
                    <RouteWrapper exact component={MobileLogin} {...props} />
                  }
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            )}
          </Routes>
        </GoogleOAuthProvider>
      </ScrollToTop>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RoutesObj);
