import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import {
  kmsCreateAccount,
  kmsSignAccount,
  updateUser,
  deemSignup,
} from "../services/actions/api";
import { Box, Button } from "@mui/material";

const Landing = (props) => {
  const { pathname } = useLocation();
  console.log("pathname", pathname);
  const navigate = useNavigate();

  // let layout = props.match.params.id;
  const { user } = props;
  let [loginStatus, setLoginStatus] = useState("Loading...");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [signMessage, setSignMessage] = useState(``);

  const [deemClientId, setDeemClientId] = useState(``);

  let memberInfoData = {
    name: ``,
    logo: "",
    bg_color: "",
    btn_color: "",
    btn_text_color: "",
    txt_color: "",
    btn_name: "",
  };
  const [memberInfo, setMemberInfo] = useState(memberInfoData);

  // for handling iframe messaging
  useEffect(() => {
    if (["/deem/aiea", "/deem/bg"].includes(pathname)) {
      if (pathname == "/deem/aiea") {
        setDeemClientId(process.env.REACT_APP_DEEM_CLIENT_ID_AIEA);
      }
      if (pathname == "/deem/bg") {
        setDeemClientId(process.env.REACT_APP_DEEM_CLIENT_ID_BG);
      }
      window.addEventListener("message", function (event) {
        if (event && event.data && event.data.event === "deem_event") {
          setLoading(true);
          props.deemSignup(event.data.query, pathname, (response) => {
            if (response.type === "error") {
              setError(response.message);
              setLoading(false);
            }
          });
        }
        if (event && event.data && event.data.event === "sign_data") {
          setSignMessage(event.data.message);
          setMemberInfo(event.data.member);
        }
      });
    } else {
      navigate("/step3");
    }
  }, []);

  useEffect(() => {
    if (Object.keys(user.data).length > 0) {
      if (user.data.public_address == null || user.data.public_address === "") {
        setLoginStatus("Creating Account...");
        props.kmsCreateAccount(user.data, (resp) => {
          if (resp.type == "success") {
            props.updateUser({
              jwt_token: user.data.token,
              public_address: resp.data.public_address,
              encrypted_buffer: resp.data.buffer_token,
            });
          } else {
            setError(resp.message);
          }
        });
      } else {
        setLoginStatus("Signing Account...");
        props.kmsSignAccount(
          {
            wallet: user.data.wallet,
            wallet_type: user.data.wallet_type,
            secrete_key: user.data.encrypted_buffer,
            address: user.data.public_address,
            message: signMessage,
          },
          (response) => {
            if (response.type == "success") {
              window.parent.postMessage(
                {
                  event: "sign_user_px",
                  profile_img: user.data.picture,
                  email: "",
                  mobile: user.data.wallet,
                  wallet_type: user.data.wallet_type,
                  las_accessed_by: user.data.las_accessed_by,
                  created_by: user.data.created_by,
                  eth_address: user.data.public_address,
                  signature: response.signature,
                  fname: user.data?.firstName,
                  lname: user.data?.lastName,
                },
                "*"
              );
            } else {
              setError(response.message);
            }
          }
        );
      }
    }
  }, [user]);

  const deemLogin = () => {
    let url = `https://api.redeem.xyz/v1/oauth/authorize?response_type=code&client_id=${deemClientId}&redirect_uri=${process.env.REACT_APP_DEEM_CALLBACK_URL}&scope=wallet contactinfo:phone&state=teststate123`;
    window.open(
      url,
      "deem_login",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
    );
  };

  return (
    memberInfo.btn_name !== "" && (
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        {error && <p style={{ color: "red" }}>{error}</p>}
        {loading ? (
          <div>{loginStatus}</div>
        ) : (
          <>
            <Button
              onClick={deemLogin}
              fullWidth
              sx={{
                borderRadius: "0.75rem",
                px: "1rem",
                fontSize: "20px",
                fontFamily: 'General Sans Medium',
                fontWeight: "500",
                background: memberInfo.btn_color,
                boxShadow:
                  "0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset, 0px 1px 3px 0px rgba(47, 43, 67, 0.10)",
                color: memberInfo.btn_text_color,
                "&:hover": {
                  background: memberInfo.btn_color,
                  color: memberInfo.btn_text_color,
                },
              }}
            >
              <img src="/assets/mobile-icon.svg" alt="mobile icon" width="22" />
              <Box component="span" ml="0.5rem">{memberInfo.btn_name}</Box>
            </Button>
          </>
        )}{" "}
      </main>
    )
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  kmsCreateAccount,
  kmsSignAccount,
  updateUser,
  deemSignup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
