import React, { Suspense, StrictMode } from "react";
import { Provider } from "react-redux";
import { stopReportingRuntimeErrors } from "react-error-overlay";

import configureStore from "./services/configureStore";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Routes from "./routes";
import "./style/style.css";
import "./App.css";

const store = configureStore();

if (process.env.REACT_APP_NODE_ENV === "production") {
  // stopReportingRuntimeErrors(); // disables error overlays
}

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={null}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </Suspense>
    </Provider>
  );
}

export default App;
