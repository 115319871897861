const initialState = {
}

export function pageAreaReducer(state = initialState, action){
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "PAGE_SET_AREA":
      return action.data;
  }
  return state;
}

export function userReducer(state = {type:"",login:false, data:{}, message:""}, action){
  let obj;
  let tmp = [];
   obj = {...state};
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "RESET_USER":
      return  {type:"", login:false, data:{}, message:""}
    case "LOGIN_REQUEST":
      return {...state, type:"request"}   
    case "LOGIN_RESET":
      return {type:"", login:false, data:{}, message:""}
    case "LOGIN_SUCCESS":
      return {type:"success", login: false, data:action.data, message:""}
    case "LOGIN_UPDATE_SUCCESS":
      return {type:"success", login: false, data:action.data, message:""}
    case "LOGIN_UPDATE_SIGNATURE_SUCCESS":
      return {...obj, type:"success", login: true}
    case "LOGIN_ERROR":
      return {type:"error", login:false, data: {}, message:action.message}
  }
  return state;
}
