import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

import GeneralSansRegular from './fonts/GeneralSans-Regular.otf';
// Create a theme instance.
const theme = createTheme({
  palette: {
    background: {
      default: "#000000"
    },
    text: {
      primary: "#000000"
    },
    primary: {
      main: '#1A1616',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: 'General Sans'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'General Sans';
          font-style: normal;
          font-weight: normal;
          src: local('General Sans'), url(${GeneralSansRegular}) format('otf');
        }
      `,
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
          borderBottom: '1px solid rgba(255, 255, 255, 0.15)'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 6,
          paddingBottom: 6,
          textTransform: 'none',
          fontSize: '13px',
          fontFamily: "General Sans"
        },
        outlined: {
          paddingTop: 15,
          paddingBottom: 15,
          paddingLeft: 40,
          paddingRight: 40,
        },
        "&.Mui-disabled": {
          color: '#cacaca',
          backgroundColor: '#204154'
        }
      },
      variants: [
        {
          props: {color: 'secondary'},
          style: {
            borderRadius: '100px',
            backgroundColor: '#ffffff',
            borderColor: '#000000',
            color: "#000000",
            "&:hover": {
              backgroundColor: '#ffffff',
            borderColor: '#000000',
            }
          }
        }
      ]
    }
  }
});

export default theme;
