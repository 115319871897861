import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { connect } from "react-redux";
import {
  signUpUser,
  kmsCreateAccount,
  kmsSignAccount,
  updateUser,
  appleSignup,
  emailSignup,
  resendEmail,
  checkEmailLogin,
} from "../services/actions/api";
import AppleLogin from "react-apple-login";
import {
  Box,
  Button,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import helper from "../utils/index";

const Landing = (props) => {
  let isLoggedIn = useRef(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const divRef = useRef(null);
  const emailRef = useRef(null);
  const { user } = props;
  let [loginStatus, setLoginStatus] = useState("Loading...");
  const [loading, setLoading] = useState(false);
  const [returningUser, setReturningUser] = useState(false);
  const [emailVerify, setEmailVerify] = useState(0);
  const [timerValue, setTimerValue] = useState(0);
  const [emailError, setEmailError] = useState("");
  const [emailText, setEmailText] = useState("");
  const [emailResponse, setEmailResponse] = useState({});
  const [error, setError] = useState("");
  const [signMessage, setSignMessage] = useState(
    `Welcome to Texas Eclipse 2024!\n\nSigning this message will not trigger a blockchain transaction, cost fees or allow us to transfer contents from your wallet.`
  );

  let memberInfoData = {
    name: ``,
    logo: "",
    bg_color: "#0A2033",
    btn_color: "#0C8BF4",
    btn_text_color: "#ffffff",
    txt_color: "#000000",
  };
  const [memberInfo, setMemberInfo] = useState(memberInfoData);
  const [themeType, setThemeType] = useState("");

  let interVal;

  const handleGoogle = (data) => {
    setLoading(true);
    props.signUpUser(data, (response) => {
      if (response.type == "error") {
        setError(response.message);
      }
    });
  };

  // for handling iframe messaging
  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (event && event.data && event.data.theme_type == "admin") {
        setThemeType(event.data.theme_type);
      }
      if (event && event.data && event.data.event == "sign_data") {
        setSignMessage(event.data.message);
        setMemberInfo(event.data.member);
      }
    });
    return () => {
      clearInterval(window.timerInterval);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(user.data).length > 0 && isLoggedIn.current == 0) {
      if (user.data.public_address == null || user.data.public_address == "") {
        setLoginStatus("Creating Account...");
        props.kmsCreateAccount(user.data, (resp) => {
          if (resp.type == "success") {
            props.updateUser({
              jwt_token: user.data.token,
              public_address: resp.data.public_address,
              encrypted_buffer: resp.data.buffer_token,
            });
          } else {
            setLoading(false);
            setError(resp.message);
          }
        });
      } else {
        setLoginStatus("Signing Account...");
        props.kmsSignAccount(
          {
            wallet: user.data.wallet,
            wallet_type: user.data.wallet_type,
            secrete_key: user.data.encrypted_buffer,
            address: user.data.public_address,
            message: signMessage,
          },
          (response) => {
            setLoading(false);
            if (response.type == "success") {
              window.parent.postMessage(
                {
                  event: "sign_user_px",
                  profile_img: user.data.picture,
                  email: user.data.wallet,
                  wallet_type: user.data.wallet_type,
                  las_accessed_by: user.data.las_accessed_by,
                  created_by: user.data.created_by,
                  eth_address: user.data.public_address,
                  signature: response.signature,
                  fname: user.data?.firstName,
                  lname: user.data?.lastName,
                },
                "*"
              );
              isLoggedIn.current = 1;
            } else {
              setError(response.message);
            }
          }
        );
      }
    }
  }, [user.data]);

  useEffect(() => {
    /* global google */
    if (divRef.current) {
      if (window.google) {
        google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleGoogle,
        });
        google.accounts.id.renderButton(divRef.current, {
          shape: "pill",
          theme: "outline",
          size: "large",
        });
      }
    }
  }, [divRef.current, emailVerify == 0]);

  let appleResponse = (response) => {
    if (!response.error) {
      props.appleSignup(response, (res) => {
        if (res.type == "error") {
          setError(res.message);
        }
      });
    }
  };

  let emailSignup = () => {
    let email = emailText.trim();
    if (emailVerify == 0) {
      console.log("memberInfo", memberInfo);
      setEmailError("");
      if (email == "") {
        setEmailError("Email cannot be blank.");
        return;
      }
      if (!helper.validateEmail(email)) {
        setEmailError("Email invalid");
        return;
      }
      setEmailVerify(1);
      props.emailSignup({ email, memberInfo, themeType }, (res) => {
        if (res.type == "error") {
          setEmailVerify(0);
          setError(res.message);
        } else {
          if (res.data?.returning_user) {
            setReturningUser(res.data?.returning_user);
          }
          setEmailVerify(2);
          checkForLogin(res.data);
          iframeResizeEvent("bigger");
        }
      });
    }
  };

  // let timerInterval;
  // let resendTimeout;

  let reSendEmailLink = () => {
    if (emailVerify == 2) {
      let email = emailText;
      setEmailVerify(3);
      props.resendEmail({ email, memberInfo, themeType }, (res) => {
        setEmailVerify(4);
        setEmailResponse(res.data);
        checkForLogin(res.data);

        if (res.data?.returning_user) {
          setReturningUser(res.data?.returning_user);
        }

        clearTimeout(window.resendTimeout);
        clearInterval(window.timerInterval);
        setTimerValue(60);

        window.resendTimeout = setTimeout(() => {
          clearInterval(window.timerInterval);
          setTimerValue(0);
          setEmailVerify(2);
        }, 60000);

        window.timerInterval = setInterval(() => {
          setTimerValue((prevState) => {
            return prevState - 1;
          });
        }, 1000);
      });
    }
  };

  let checkForLogin = (data) => {
    if (interVal) {
      clearInterval(interVal);
    }

    interVal = setInterval(() => {
      let memberInfo = {};
      try {
        memberInfo = JSON.parse(searchParams.get("memberinfo"));
      } catch (e) {
        memberInfo = {};
      }

      props.checkEmailLogin({ ...data, memberInfo }, (res) => {
        if (res.type == "success") {
          clearInterval(interVal);
        }
      });
    }, 5000);
  };

  function iframeResizeEvent(type) {
    window.parent.postMessage({ event: "iframe_resize", frameType: type }, "*");
  }
  return (
    <>
      {emailVerify < 2 ? (
        <main style={{ maxWidth: 215, margin: "auto", overflow: "hidden" }}>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <article
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <div>{loginStatus}</div>
            ) : (
              <>
                <div ref={divRef} />
                {/* <button className="apple-btn" onClick={(e)=>{ setLoading(true)}}>
                <img src="/assets/apple_white.svg" width="15" alt="Apple White Logo" /> Sign in with Apple
              </button> */}
                <AppleLogin
                  clientId="com.polyientx.auth.client"
                  redirectURI={`${process.env.REACT_APP_BASE_APPLE_URL}/api/apple/login`}
                  usePopup={true}
                  callback={appleResponse} // Catch the response
                  scope="email name"
                  responseMode="query"
                  render={(
                    renderProps //Custom Apple Sign in Button
                  ) => (
                    <button
                      className="apple-btn"
                      onClick={(e) => {
                        setLoading(true);
                        renderProps.onClick(e);
                      }}
                    >
                      <img
                        src="/assets/apple_white.svg"
                        width="15"
                        alt="Apple White Logo"
                      />{" "}
                      Sign in with Apple
                    </button>
                  )}
                />
              </>
            )}

            <div className="orSeperator">OR</div>
            <TextField
              sx={{
                fontSize: "11px",
                "& .MuiOutlinedInput-input": {
                  fontSize: "11px",
                  paddingRight: "10px !important",
                },
              }}
              fullWidth
              className="Add-image"
              variant="outlined"
              placeholder={"Enter an email address"}
              error={emailError !== ""}
              helperText={emailError}
              InputLabelProps={{ shrink: true }}
              label={<span>Email</span>}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => {
                        emailSignup();
                      }}
                      sx={{ fontSize: "11px", p: "6px !important" }}
                      disabled={emailVerify == 1}
                    >
                      {emailVerify == 1 ? "Wait..." : "Continue"}
                    </Button>
                  </InputAdornment>
                ),
              }}
              ref={emailRef}
              value={emailText}
              onChange={(e) => {
                setEmailError("");
                setEmailText(e.target.value);
              }}
              disabled={emailVerify == 1}
            />
          </article>
        </main>
      ) : (
        <Box sx={{ margin: "auto", overflow: "hidden", borderRadius: "15px" }}>
          <Box className="verifiedBox">
            <Typography variant="h3">
              {returningUser
                ? "Check your inbox to continue"
                : "Verify your email address"}
            </Typography>
            <img
              src="/assets/verification_loading.svg"
              style={{ margin: "20px 0" }}
              width={75}
              alt="verification waiting"
            />
            {!returningUser && (
              <Typography variant="h4">
                Check your inbox to confirm your email.
              </Typography>
            )}

            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              mt={3}
            >
              <Link
                onClick={() => {
                  reSendEmailLink();
                }}
                disabled={emailVerify != 2}
                style={
                  emailVerify == 4
                    ? { color: "gray", cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }
              >
                {" "}
                {emailVerify == 3
                  ? "Wait..."
                  : `Resend Email: ${emailText}`}{" "}
                {emailVerify == 2 ? (
                  <img
                    src="/assets/email.svg"
                    style={{ marginLeft: "3px" }}
                    width={16}
                    alt="email"
                  />
                ) : (
                  <>
                    {emailVerify == 3 && (
                      <img
                        src="/assets/wait.svg"
                        style={{ marginLeft: "3px" }}
                        height={16}
                        alt="email"
                      />
                    )}
                    {emailVerify == 4 && (
                      <>
                        <img
                          src="/assets/email-gray.svg"
                          style={{ marginLeft: "3px" }}
                          width={16}
                          alt="email"
                        />
                        <span
                          style={{
                            color: "#000",
                            fontSize: "0.625rem",
                            fontFamily: "General Sans Medium",
                          }}
                        >
                          &nbsp; ({timerValue} Sec.)
                        </span>
                      </>
                    )}
                  </>
                )}
              </Link>{" "}
              {emailVerify == 4 && timerValue > 0 && (
                <span
                  style={{
                    fontSize: "0.625rem",
                    fontFamily: "General Sans Medium",
                    marginTop: "0.5rem",
                  }}
                >
                  Email sent...
                </span>
              )}
              <Link
                mt={(emailVerify == 4 && timerValue > 0) > 0 ? 0.5 : 1.5}
                onClick={() => {
                  clearTimeout(window.resendTimeout);
                  clearInterval(window.timerInterval);
                  setTimerValue(0);

                  setEmailVerify(0);
                  setEmailError("");
                  iframeResizeEvent("small");
                }}
                style={{ cursor: "pointer" }}
              >
                Update my email address
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  signUpUser,
  kmsCreateAccount,
  kmsSignAccount,
  updateUser,
  appleSignup,
  emailSignup,
  resendEmail,
  checkEmailLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
