const helper={
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  ahex_to_rba: (ahex) => {
    //clean #
    if (ahex == "" || ahex == null || ahex == undefined) {
      return;
    }
    ahex = ahex.substring(1, ahex.length);
    ahex = ahex.split("");
  
    var r = ahex[0] + ahex[0],
      g = ahex[1] + ahex[1],
      b = ahex[2] + ahex[2],
      a = ahex[3] + ahex[3];
  
    if (ahex.length >= 6) {
      r = ahex[0] + ahex[1];
      g = ahex[2] + ahex[3];
      b = ahex[4] + ahex[5];
      a = ahex[6] + (ahex[7] ? ahex[7] : ahex[6]);
    }
  
    var int_r = parseInt(r, 16),
      int_g = parseInt(g, 16),
      int_b = parseInt(b, 16),
      int_a = parseInt(a, 16);
  
    int_a = int_a / 255;
  
    if (int_a < 1 && int_a > 0) int_a = int_a.toFixed(2);
  
    if (int_a || int_a === 0) return "rgba(" + int_r + ", " + int_g + ", " + int_b + ", " + int_a + ")";
    return int_r + ", " + int_g + ", " + int_b;
  }
}

export default helper;