import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  signUpUser,
  kmsCreateAccount,
  kmsSignAccount,
  updateUser,
  appleSignup,
} from "../services/actions/api";
import AppleLogin from "react-apple-login";

const Landing = (props) => {
  const divRef = useRef(null);
  const { user } = props;
  let [loginStatus, setLoginStatus] = useState("Loading...");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [signMessage, setSignMessage] = useState(
    `Welcome to Texas Eclipse 2024!\n\nSigning this message will not trigger a blockchain transaction, cost fees or allow us to transfer contents from your wallet.`
  );

  const handleGoogle = (data) => {
    setLoading(true);
    const signup = props.signUpUser(data, (response) => {
      if (response.type == "error") {
        setError(response.message);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (event && event.data && event.data.event === "sign_data") {
        setSignMessage(event.data.message);
      }
    });
  }, []);

  useEffect(() => {
    if (Object.keys(user.data).length > 0) {
      if (user.data.public_address == null || user.data.public_address == "") {
        setLoginStatus("Creating Account...");
        console.log(" user.data", user.data);
        props.kmsCreateAccount(user.data, (resp) => {
          if (resp.type == "success") {
            props.updateUser({
              jwt_token: user.data.token,
              public_address: resp.data.public_address,
              encrypted_buffer: resp.data.buffer_token,
            });
          } else {
            setLoading(false);
            setError(resp.message);
          }
        });
      } else {
        setLoginStatus("Signing Account...");
        console.log("user.data =>", user.data);
        props.kmsSignAccount(
          {
            wallet: user.data.wallet,
            wallet_type: user.data.wallet_type,
            secrete_key: user.data.encrypted_buffer,
            address: user.data.public_address,
            message: signMessage,
          },
          (response) => {
            setLoading(false);
            if (response.type == "success") {
              console.log("user.data -sign_user_px--=>", {
                event: "sign_user_px",
                profile_img: user.data.picture,
                email: user.data.email,
                eth_address: user.data.public_address,
                signature: response.signature,
                fname: user.data?.firstName,
                lname: user.data?.lastName,
              });
              window.parent.postMessage(
                {
                  event: "sign_user_px",
                  profile_img: user.data.picture,
                  email: user.data.email,
                  eth_address: user.data.public_address,
                  signature: response.signature,
                  fname: user.data?.firstName,
                  lname: user.data?.lastName,
                },
                "*"
              );
            } else {
              setError(response.message);
            }
          }
        );
      }
    }
  }, [user.data]);

  useEffect(() => {
    /* global google */

    if (divRef.current) {
      if (window.google) {
        google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleGoogle,
        });
        google.accounts.id.renderButton(divRef.current, {
          shape: "pill",
          theme: "outline",
          size: "large",
        });
      }
    }
  }, [divRef.current]);

  let appleResponse = (response) => {
    if (!response.error) {
      props.appleSignup(response, (res) => {
        if (res.type == "error") {
          setError(res.message);
        }
      });
    }
  };

  return (
    <>
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        {error && <p style={{ color: "red" }}>{error}</p>}
        <article style={{ display: "flex", flexDirection: "column" }}>
          {loading ? (
            <div>{loginStatus}</div>
          ) : (
            <>
              <div ref={divRef} />
              <AppleLogin
                clientId="com.polyientx.auth.client"
                redirectURI={`${process.env.REACT_APP_BASE_APPLE_URL}/api/apple/login`}
                usePopup={true}
                callback={appleResponse} // Catch the response
                scope="email name"
                responseMode="query"
                render={(
                  renderProps //Custom Apple Sign in Button
                ) => (
                  <button
                    className="apple-btn"
                    onClick={(e) => {
                      setLoading(true);
                      renderProps.onClick(e);
                    }}
                  >
                    <img
                      src="/assets/apple_white.svg"
                      width="15"
                      alt="Apple White Logo"
                    />{" "}
                    Sign in with Apple
                  </button>
                )}
              />
            </>
          )}
        </article>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  signUpUser,
  kmsCreateAccount,
  kmsSignAccount,
  updateUser,
  appleSignup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
